import React from "react"
import "./subfooter.css"
import { Link } from "gatsby"
import rb from "../../assets/ergebon_dsn_1.png"

const Subfooter = () => {
  return (
    <section className="sub-footer">
      <div className="copyright">
        <p className="ph">
          Special thanks to Anja Janjic &copy; for Great looking logo
          <br />
          <Link to="/"> &copy; 2020 leapsyn-sci</Link>
        </p>
      </div>
      <div className="develop-by">
        <p className="ph">Designed & Developed by</p>
        <img src={rb} alt="ergebon design and develop" className="logo" />
      </div>
    </section>
  )
}

export default Subfooter
