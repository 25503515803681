import React from "react"
import logo from "../../assets/logo.png"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./footer.css"

const fooLinks = [
  {
    to: "/",
    name: "Home",
    anchor: false,
  },
  {
    to: "/#about",
    name: "Team",
    anchor: true,
  },
  {
    to: "/activities",
    name: "Activities",
    anchor: false,
  },
  {
    to: "/gallery",
    name: "Gallery",
    anchor: false,
  },
  {
    to: "/contact",
    name: "Contact",
    anchor: false,
  },
]
const Footer = () => {
  return (
    <section className="footer">
      <div className="two">
        <img src={logo} className="ft-logo" alt="leapsyn-sci" />
      </div>
      <div className="one sitemap">
        <div className="sitemap-wrapper">
          <h5 className="title">Sitemap</h5>
          <ul>
            {fooLinks.map((ln, i) => (
              <li key={i} className="link">
                {ln.anchor ? (
                  <AnchorLink to={ln.to}>{ln.name}</AnchorLink>
                ) : (
                  <Link to={ln.to}>{ln.name}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="one privacy">
        <ul className="links">
          <li className="link">
            <Link to="/tac">Terms of Use</Link>
          </li>
          <li className="link">
            <Link to="/privacy">Privacy and Policy</Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Footer
