import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { DispatchContext } from "../../contexts/state-context-provider"

import "./nav.css"

import fb from "../../assets/fb.svg"
import rg from "../../assets/rg.svg"
import inst from "../../assets/inst.svg"
import tw from "../../assets/tw.svg"

const LangSelect = () => {
  const options = ["rs", "en"]
  const dispatch = useContext(DispatchContext)

  return (
    <div className="lang-sel">
      {options.map((option, ix) => (
        <button
          key={ix}
          className="btn"
          onClick={() => dispatch({ type: "TOGGLE_LANGUAGE", value: option })}
        >
          {option}
        </button>
      ))}
    </div>
  )
}

const Nav = ({ padded }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pageLinks {
            to
            name
          }
          outerLinks {
            to
            name
          }
        }
      }
    }
  `)
  const [menu, setMenu] = useState(false)
  const media = {
    fb: fb,
    inst: inst,
    tw: tw,
    rg: rg,
  }

  return (
    <nav className={padded ? "nav" : "nav nav-fixed"} id="nav">
      {/* inner */}
      <div className="container">
        <div className="nav-col--mobile">
          {!menu ? (
            <button
              className="btn-ham--mobile"
              onClick={() => setMenu(!menu)}
              aria-label={`Ham-manu`}
            />
          ) : null}
          {menu ? (
            <ul className="items--mobile">
              <button
                className="btn-close--mobile"
                onClick={() => setMenu(!menu)}
                aria-label="Close-Btn"
              />
              {data.site.siteMetadata.pageLinks.map((link, i) => (
                <li key={i} className="page-link">
                  <Link to={link.to}>{link.name}</Link>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="nav-col--desktop">
          <ul className="items">
            {data.site.siteMetadata.pageLinks.map((link, i) => (
              <li key={i} className="page-link--desktop">
                <Link
                  to={
                    link.to !== "/#about" && link.to !== "/"
                      ? `${link.to}/#page-title`
                      : link.to
                  }
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* outer */}

        <div className="res-col">
          <ul className="items">
            {data.site.siteMetadata.outerLinks.map((link, i) => (
              <li className="outer-link" key={i}>
                <a href={link.to} className="media-link">
                  <img
                    src={media[link.name]}
                    alt={`${link.name} media`}
                    className="media-icon"
                  />
                </a>
              </li>
            ))}
          </ul>
          <LangSelect />
        </div>
      </div>
    </nav>
  )
}

export default Nav
