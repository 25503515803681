import PropTypes from "prop-types"
import React from "react"
import "./header.css"

import headerImage from "../../assets/header-mockup-2.png"
import vector from "../../assets/vector.svg"
import logo from "../../assets/logo.png"
import logoNg from "../../assets/ng.jpg"

const Section = () => {
  const condition = () => {
    const check_date = new Date()
    let d = check_date.getDate()
    let m = check_date.getMonth()+1
    if ((m === 12 && d >= 20) || (m === 1 && d <= 27)) {
      return true
    } 
  }

  return (
    <div className="container">
      <div className="box mod">
        {condition() ? <img src={logoNg} alt="leapsyn-sci logo newyear" className="hd-logo-ng" /> :
        <img src={logo} alt="leapsyn-sci logo" className="hd-logo" />}
      </div>
      <div className="box">
        <img src={vector} alt="vector" className="vector" />
        <img src={headerImage} alt="slides" className="image" />
      </div>
    </div>
  )
}

// todo: add this to other pages...
const Header = ({ siteTitle }) => (
  <header className="header">
    <div style={{ display: "none" }}>{siteTitle}</div>
    <Section />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
