import React from "react"
import { Link } from "gatsby"
import "./partners.css"

const importPartners = r => {
  return r.keys().map(r)
}

const Partners = () => {
  const partners = importPartners(
    require.context("../../assets/partners", false, /\.(png|jpe?g|svg)$/)
  )
  //   add language support to parLinks
  const parLinks = [
    "https://www.imgge.bg.ac.rs/index.php/yu/",
    "https://singidunum.ac.rs/",
    "http://www.chem.bg.ac.rs/",
    "http://www.imsi.bg.ac.rs/",
  ]
  return (
    <section className="flex">
      {partners.map((par, id) => (
        <Link key={id} to={parLinks[id]} className="child">
          <img src={par.default} alt={`partner logo ${id}`} />
        </Link>
      ))}
    </section>
  )
}

export default Partners
