import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/header"
import Nav from "./Nav/nav"
import Partners from "./Partners/partners"
import Footer from "./Footer/footer"
import Subfooter from "./Subfooter/subfooter"
import "./layout.css"
import "../styles/var.css"
import "../styles/formating.css"

const FormatMod = ({ children, modSection }) => {
  const modSectionOption = {
    mod: "layout-mod--a",
    mod2: "layout-mod--b",
    mod3: "layout-mod--c",
    mod4: "layout-mod--d",
    foo: "layout-footer",
    subfoo: "layout-subfooter",
  }

  return <div className={modSectionOption[modSection]}>{children}</div>
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query siteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [padded, setPadded] = useState(true)
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      require("smooth-scroll")('a[href*="#"]')
    }

    const child = document.querySelector("nav")
    var childTop = child.offsetTop
    const onScroll = () => setPadded(window.pageYOffset < childTop)
    
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [])
  

  return (
    <div id="wrapper">
      <Header siteTitle={data.site.siteMetadata.title} />
      <Nav padded={padded} />
      <main>
        {children.map((child, i) =>
          child.props.id ? (
            <FormatMod modSection={child.props.id} key={i}>
              {child}
            </FormatMod>
          ) : (
            <React.Fragment key={i}>{child}</React.Fragment>
          )
        )}
      </main>
      <FormatMod modSection={"mod4"}>
        <Partners />
      </FormatMod>
      <FormatMod modSection={"foo"}>
        <Footer />
      </FormatMod>
      <FormatMod modSection={"subfoo"}>
        <Subfooter />
      </FormatMod>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
